import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Appointment from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Pricing from '@solid-ui-blocks/Pricing/PricingGMB'
import Companies from '@solid-ui-blocks/Companies/Block01'
import BuildBrand from '@solid-ui-blocks/FeaturesWithPhoto/Block04'
import WideCTA from '@solid-ui-blocks/Hero/Block03'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  const schema =
  {
    "@context": "https://schema.org",
    "@graph":
      [
        {
          "@type":"Product",
          "name":"Bitconcepts",
          "description":"Website Design",
          "aggregateRating":{"@type":"AggregateRating","reviewCount":9,"ratingValue":5,"bestRating":5,"worstRating":1},
          "image":"https://bitconcepts.de/static/82275983ca2245e1976573fbbe2f867d/a067c/logo.webp"
        }		
      ]
  }

  return (
    <Layout theme={theme} {...props}>
      <Seo
        title='Unternehmensprofil | Bitconcepts'
        description='Bitconcepts - Agentur für Digitalmarketing, Suchmaschinenwerbung, Webdesign, Social Media und mehr. Erfahre mehr!'
        schemaMarkup={schema}
      />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <Appointment />
      {/* Blocks */}
      <Header content={content['header']} menuJustify='space-between' />
      <Companies content={content['companies']} />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='full' sx={styles.buildBrandContainer}>
        <BuildBrand content={content['gmb-management']} />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Pricing content={content['pricing']} />
      <Divider space='5' />
      <WideCTA content={content['bottomcta']} />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageUnternehmensprofilBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/unternehmensprofil", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
